// src/pages/AboutPage.js

import React from 'react';
import './aboutpage.css'; // Import the CSS file

const AboutPage = () => {
  return (
    <div className="about-container">
      <h1>Travel With Me! </h1>
      <p>
        Follow along on my journey as I study abroad in Barcelona for the next four months. Check out my favorite sites, tastes,
        and stories as I travel across Europe. 
      </p>
      {/* Insert additional content such as images or anecdotes that tell Nikita's story */}
    </div>
  );
};

export default AboutPage;

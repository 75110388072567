// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCO8ubixR9MesdbLXCVK0ZjqKIzsfH5fr8",
  authDomain: "nikitas-world.firebaseapp.com",
  projectId: "nikitas-world",
  storageBucket: "nikitas-world.appspot.com",
  messagingSenderId: "569945095824",
  appId: "1:569945095824:web:64c432cba83281c6932215",
  measurementId: "G-MLW4013HTE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const analytics = getAnalytics(app);
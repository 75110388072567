// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar'; // Import NavBar
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import TravelWriting from './pages/TravelWriting';
import PicturesPage from './pages/PicturesPage';
import LoginForm from './components/LoginForm';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/travel-writing" element={<TravelWriting />} />
        <Route path="/pictures" element={<PicturesPage />} />
        <Route path="/login" element={<LoginForm />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from 'react';
import './travelwriting.css';

const TravelWriting = () => {
  // Manually defined Notion links and titles
  const posts = [
    { id: 1, title: 'Post Title 1', link: 'https://persistent-crop-dc6.notion.site/Travel-6e62250a10484c26a6b5819279ea397c' },
    { id: 2, title: 'Post Title 2', link: 'https://www.notion.so/Link2' },
    // Add more posts here
  ];

  return (
    <div className="travel-writing-container">
      <h1>Travel Writing</h1>
      <div className="posts-grid">
        {posts.map(post => (
          <div key={post.id} className="post">
            <a href={post.link} target="_blank" rel="noopener noreferrer" className="notion-link">
              {post.title}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TravelWriting;

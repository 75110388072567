// components/NavBar.js

import React from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css'; // CSS file for styling the nav bar

const NavBar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="nav-logo">Nikita's World</Link>
      <div className="nav-links">
        <Link to="/about">About</Link>
        <Link to="/travel-writing">Travel Writing</Link>
        <Link to="/pictures">Pictures</Link>
      </div>
    </nav>
  );
};

export default NavBar;

import React, { useState } from 'react';
import { auth } from '../firebase/firebase'; // Adjust the path according to your structure
import './styles.css'

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(email, password);
      console.log('Logged in');
      // Redirect to a different page or show a success message
    } catch (error) {
      console.error('Login error', error);
      // Handle errors like wrong password, user not found etc.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
      <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
      <button type="submit">Login</button>
    </form>
  );
};

export default LoginForm;

// HomePage.js

import React from 'react';
import './homepage.css'; // Adjust the path to your CSS file
//import AryanImage from './aryan.jpg';

const HomePage = () => {
  return (
    <div className="home-container">
      <h1>Welcome to Nikita's World</h1>
      <p>Join me on my adventures as I explore new places, enjoy Taylor Swift's music, and try delightful cuisines from around the world.</p>
      <div className="image-container">
        {/* Include a travel-themed image here */}
        {/* <img src={AryanImage} alt="Aryan" /> */}
      </div>
    </div>
  );
};

export default HomePage;

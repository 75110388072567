// src/pages/HomePage.js

import React from 'react';
import './homepage.css'; // Import the CSS file

const HomePage = () => {
  return (
    <div className="home-container">
      <section className="hero-section">
        <h1> Discover the World with Nikita</h1>
        <p>Embark on a journey through hidden gems and wonderful delights.</p>
        <button className="cta-button">Start Exploring</button>
      </section>
      <section className="favorites-section">
        <h2>Nikita's Travel Highlights</h2>
        <div className="favorites-container">
          {/* Replace with actual travel highlight images and descriptions */}
          {/* Example placeholders: */}
          <div className="favorite-item">
            <img src="/images/paris.jpg" alt="Paris" />
            <h3>Paris</h3>
            <p>The City of Light and Love.</p>
          </div>
          <div className="favorite-item">
            <img src="/images/tokyo.jpg" alt="Tokyo" />
            <h3>Tokyo</h3>
            <p>A perfect blend of tradition and future.</p>
          </div>
          <div className="favorite-item">
            <img src="/images/tuscany.jpg" alt="Tuscany" />
            <h3>Tuscany</h3>
            <p>Wine, rolling hills, and Italian charm.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
